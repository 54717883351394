.chatBotIconContainer {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  line-height: 50%;

}

.chatBotIcon {
  color: #6345C7;
  position: absolute;
  top: 12px;
  left: 10px;
  cursor: pointer;
}

.chatBot {
  position: fixed;
  right: 50px;
  float: right;
  bottom: 85px;
  z-index: 5000;
}

.showAppointments ul,
.services ul {
  list-style: none;
  font-size: larger;
  margin-top: 20px;
}

.showAppointments ul li,
.services ul li {
  margin-top: 10px;
  transition: all .3s ease-in-out;
}

.showAppointments ul li:hover,
.services ul li:hover {
  padding-left: 5px;
}


/*  */

.openingHours h4 {
  margin-bottom: 10px;
}
/* DatePicker container */
.rdp-table {
  padding: 10px;
  margin: 10px;
  width: 263px;
  max-width: 100%;
}

.rdp-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
}

.rdp-caption_label {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}
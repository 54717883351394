.calnder_container {
    width: 100%;
    margin-bottom: 10px;

}

.calnder_container .header {
    margin-bottom: 15px;
}

.calnder_container .header .text {
    text-align: center;
    font-size: 14px;
}

.calnder {
    width: 100%;
    height: 50px;
    border: none;
    color: #fff;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    border-radius: 10px;
    outline: none;
    background-color: rgb(110, 72, 170);
}